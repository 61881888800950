<template>
  <div>
    <div class="mt-6 sm:mt-12 mb-6">
      <div class="flex flex-col md:flex-row md:space-x-6">
        <div class="flex-1">
          <h4 class="capitalize text-blue-800 font-semibold text-xl">
            Delete Profile
          </h4>
          <p class="text-sm mt-2 text-grayDark font-light">
            All your profile data will be deleted effective immediately.
          </p>
          <div class="flex-1 flex flex-col items-start justify-end space-x-4 mt-4 sm:mt-0">
            <ConfirmDialog
              @confirm="deleteProfile"
              confirm-action="Yes, Delete"
              dialog-title="Delete Profile?"
              dialog-text="Are you sure you want to delete your profile? This action is irreversible!"
              class="bg-white border border-red-600 text-white mt-8 px-12 py-4 rounded-full bg-red-500 text-white text-sm transition duration-150 ease-in-out hover:shadow-3xl focus:shadow-outline capitalize"
            >
              Delete Profile
            </ConfirmDialog>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import api from '@/api'
  import ConfirmDialog from '@/components/shared/ConfirmDialog'

  export default {
    props: {
      model: Object,
      entity: String,
    },

    components: {
      ConfirmDialog,
    },

    methods: {
      deleteProfile(callback) {
        if (this.entity === 'show') {
          return this.deleteShowProfile(callback)
        }

        return this.deleteGuestProfile(callback)
      },

      deleteShowProfile(callback) {
        api.delete(`/shows/${this.model.id}`)
          .then(() => {
            callback()
            this.$toast.success(`The ${this.entity} profile was deleted.`)
            this.$router.push({name: 'ShowIndex'})
          })
          .catch(error => {
            callback()

            if (error.response) {
              return this.$toast.error(error.response.data.message)
            }

            this.$toast.error('Error! Something went wrong.')
          })
      },

      deleteGuestProfile(callback) {
        api.delete(`/guests/${this.model.id}`)
          .then(() => {
            callback()
            this.$toast.success(`The ${this.entity} profile was deleted.`)
            this.$router.push({name: 'GuestCreate'})
          })
          .catch(error => {
            callback()

            if (error.response) {
              return this.$toast.error(error.response.data.message)
            }

            this.$toast.error('Error! Something went wrong.')
          })
      },
    }
  }
</script>