<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.5929 7.65659C14.4722 8.53596 14.4722 9.96055 13.5929 10.8399C12.7135 11.7193 11.2889 11.7193 10.4095 10.8399C9.53016 9.96055 9.53016 8.53596 10.4095 7.65659C11.2889 6.77723 12.7135 6.77723 13.5929 7.65659" :stroke="fill" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.3214 16.5429C16.1003 15.1533 14.9058 14.0889 13.4552 14.0889H10.542C9.09037 14.0889 7.89687 15.1533 7.67578 16.5429" :stroke="fill" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0019 21.0036H6.99776C4.78784 21.0036 2.99609 19.2118 2.99609 17.0019V6.99776C2.99609 4.78784 4.78784 2.99609 6.99776 2.99609H17.0019C19.2118 2.99609 21.0036 4.78784 21.0036 6.99776V17.0019C21.0036 19.2118 19.2118 21.0036 17.0019 21.0036Z" :stroke="fill" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "GuestDetailsIcon",
  props: {
    fill: {
      type: String,
      default: "#737373"
    }
  }
};
</script>
