<template>
  <div @click="$emit('toggled', !active)">
    <label for="pitch"></label>
    <input type="checkbox" id="toggle" :checked="active" hidden aria-hidden="true"/>
    <div class="toggle"></div>
  </div>
</template>

<script>
export default {
  name: 'Toggle',
  props: {
    active: {
      type: Boolean,
      default: true
    },
  }
}
</script>

<style scoped>
  label {
    display: inline-block;
    width: 30px;
    margin-top: 33px;
  }
  .toggle {
    border-radius: 12px;
    display: inline-block;
    height: 16px;
    position: relative;
    transition: all;
    vertical-align: middle;
    width: 36px;
    background-color: #D4D4D4;
  }
  .toggle:after {
    background-color: #fff;
    border-color: #292546;
    border-width: .01rem;
    border-radius: 50%;
    content: '';
    display: block;
    position: absolute;
    height: 20px;
    width: 20px;
    top: -2px;
    right: 16px;
  }
  input:checked + .toggle {
    background-color: #B503D0;
    border: 2px solid #B503D0;
    background-color: #B503D0;
  }
  input:checked + .toggle:after {
    background-color: #fff;
    border-color: #292546;
    border-width: .01rem;
    width: 20px;
    height: 20px;
    top: -4px;
    right: -2px;
  }
</style>