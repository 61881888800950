<template>
  <div>
    <div v-if="working" class="mt-12 max-w-3xl w-full flex items-center justify-center py-6">
      <loading-icon class="h-2 text-pink-500" />
    </div>
    <div v-else class="py-10 max-w-3xl w-full">
      <div>
        <h3 class="text-blue-800 font-semibold text-xl">Application</h3>
      </div>
      <p :class="['text-grayDark font-normal mt-6 mb-10', isEnabled ? '' : 'opacity-40']">Ask questions for future shows that want to book you</p>
      <template :class="[isEnabled ? '' : 'opacity-40']" v-if="questions.length">
        <div class="space-y-8">
          <BookingQuestionRow
            v-for="(question, index) in questions"
            :key="`question-${index}`"
            :question="question"
            :model="model"
            :entity="entity"
            @delete-question="deleteQuestion(index)"
            @update-question="updateQuestion($event, index)"
            ref="bookingRows"
            :isEnabled="isEnabled"
          />
        </div>
      </template>
      <template :class="[isEnabled ? '' : 'opacity-40']" v-else>
        <div class="h-12 mb-2 flex justify-center items-center text-sm text-gray-500 bg-gray-50 rounded-lg border-2 border-dashed opacity-75">
          No questions added.
        </div>
      </template>
      <div :class="[isEnabled ? '' : 'opacity-40 pointer-events-none']" class="mt-12">
        <button type="button" class="text-pink-500 underline" @click.prevent="addQuestion">+ Add another question</button>
      </div>
    </div>
  </div>
</template>

<script>
  import BookingQuestionRow from './BookingQuestionRow'

  export default {
    name: 'BookingQuestions',

    components: {BookingQuestionRow},

    props: {
      model: Object,
      entity: String,
      isEnabled: {
        type: Boolean,
        required: true
      }
    },

    data() {
      return {
        working: true,
        questions: []
      }
    },

    methods: {
      addQuestion() {
        this.questions.push({
          question: ''
        })

        this.$nextTick(() => {
          this.$refs.bookingRows[this.$refs.bookingRows.length - 1].edit = true
        })
      },

      updateQuestion(question, index) {
        this.questions.splice(index, 1, question)
      },

      deleteQuestion(index) {
        this.questions.splice(index, 1)
      }
    },

    mounted() {
      if (this.entity == 'show') {
        this.$store
          .dispatch(`shows/fetchBookingQuestions`, this.model.id)
          .then(({ data }) => {
            this.questions = data.data
          })
          .finally(() => this.working = false)
      } else {
        this.$store
          .dispatch(`guest/fetchBookingQuestions`, this.model.id)
          .then(({ data }) => {
            this.questions = data.data
          })
          .finally(() => this.working = false)
      }
    }
  }
</script>