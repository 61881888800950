<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 6.60907C6 5.64451 7.00466 5.04354 7.80465 5.52955L17.4046 11.7779C18.1985 12.2602 18.1985 13.4547 17.4046 13.9369L7.80465 20.1853C7.00466 20.6713 6 20.0703 6 19.1058V6.60907Z"
      :stroke="fill"
      stroke-width="1.5"
    />
  </svg>
</template>

<script>
export default {
  name: "ProStatsIcon",
  props: {
    fill: {
      type: String,
      default: "#737373"
    }
  }
};
</script>
