<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19 3.66699C20.105 3.66699 21 4.56199 21 5.66699V19.667C21 20.772 20.105 21.667 19 21.667H5C3.895 21.667 3 20.772 3 19.667V5.66699C3 4.56199 3.895 3.66699 5 3.66699H19Z" :stroke="fill" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3 15.667H7.382C7.761 15.667 8.107 15.881 8.276 16.22L8.723 17.114C8.893 17.453 9.239 17.667 9.618 17.667H14.382C14.761 17.667 15.107 17.453 15.276 17.114L15.723 16.22C15.893 15.881 16.239 15.667 16.618 15.667H21" :stroke="fill" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3 9.66699H7.382C7.761 9.66699 8.107 9.88099 8.276 10.22L8.723 11.114C8.893 11.453 9.239 11.667 9.618 11.667H14.382C14.761 11.667 15.107 11.453 15.276 11.114L15.723 10.22C15.893 9.88099 16.239 9.66699 16.618 9.66699H21" :stroke="fill" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "PitchIcon",
  props: {
    fill: {
      type: String,
      default: "#737373"
    }
  }
};
</script>
