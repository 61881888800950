<template>
  <div>
    <div v-if="! edit" :class="['w-full flex items-center justify-between', isEnabled ? '' : 'opacity-40']">
      <div class="flex flex-col w-full">
        <div class="flex-1 flex items-center w-11/12 p-6 border border-2 border-zinc-600 rounded-lg mb-3">
          <span class="text-blue-800 font-normal text-lg">{{ question.question }}</span>
        </div>
        <div :class="['flex flex-row', !isEnabled ? 'pointer-events-none' : '']">
          <ConfirmDialog
            @confirm="deleteQuestion"
            confirm-action="Yes, Delete"
            dialog-title="Delete Question?"
            dialog-text="Are you sure you want to delete this question?"
            class="ml-8 flex items-center text-pink-500"
          >
            <TrashAltIcon2 class="w-6 h-6 text-red-500" />
            <span class="ml-2 mt-1 text-red-500">Delete</span>
          </ConfirmDialog>
        </div>
      </div>
    </div>
    <div v-else class="w-full">
      <ValidationObserver ref="questionForm" v-slot="{ handleSubmit }">
        <form ref="form" @submit.prevent="handleSubmit(update)" method="post">
          <div>
            <ValidationProvider mode="lazy" rules="required|max:255" vid="question" name="Question" v-slot="{ errors }">
              <TextInput v-model="form.question" :errors="errors" placeholder="Question"/>
            </ValidationProvider>
          </div>
          <div class="mt-4 flex items-center justify-end">
            <SaveOrCancel @cancel="cancelEdit" :working="working"/>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/shared/TextInput";
import TrashAltIcon2 from "@/components/svgs/TrashAltIcon2";
import SaveOrCancel from "@/components/shared/SaveOrCancel";
import ConfirmDialog from "@/components/shared/ConfirmDialog";

export default {
  name: 'BookingQuestionRow',

  props: {
    question: Object,
    model: Object,
    entity: String,
    isEnabled: {
      type: Boolean,
      required: true
    },
    type: {
      type: String,
      default: 'booking',
    }
  },

  components: {
    TrashAltIcon2,
    TextInput,
    SaveOrCancel,
    ConfirmDialog,
  },

  data() {
    return {
      working: false,
      edit: false,
      form: {
        question: null,
      },
    }
  },

  watch: {
    edit(edit) {
      if (edit) {
        return
      }

      this.form.question = this.question.question
    },
  },

  methods: {
    update() {
      this.working = true

      if (this.entity == 'guest') {
        this.handleGuestBookingQuestion()
      }

      if (this.entity == 'show') {
        this.handleShowBookingQuestion()
      }
    },

    handleGuestBookingQuestion() {
      if (this.question.id) {
        this.updateGuestBookingQuestion()
      } else {
        this.storeGuestBookingQuestion()
      }
    },

    updateGuestBookingQuestion() {
      this.$store.dispatch('guest/updateBookingQuestion', {
        guestId: this.model.id,
        questionId: this.question.id,
        question: this.form.question,
        type: this.type,
      }).then(({ data }) => {
        this.working = false
        this.edit = false
        this.$emit('update-question', data.data)
      }).catch(error => {
        if (error.response) {
          this.$refs.form.setErrors(error.response.data.errors)
        }
        this.working = false
      })
    },

    storeGuestBookingQuestion() {
      this.$store.dispatch('guest/storeBookingQuestion', {
        guestId: this.model.id,
        question: this.form.question,
        type: this.type,
      }).then(({ data }) => {
        this.working = false
        this.edit = false
        this.$emit('update-question', data.data)
      }).catch(error => {
        if (error.response) {
          this.$refs.form.setErrors(error.response.data.errors)
        }
        this.working = false
      })
    },

    handleShowBookingQuestion() {
      if (this.question.id) {
        this.updateShowBookingQuestion()
      } else {
        this.storeShowBookingQuestion()
      }
    },

    updateShowBookingQuestion() {
      this.$store.dispatch('shows/updateBookingQuestion', {
        showId: this.model.id,
        questionId: this.question.id,
        question: this.form.question,
        type: this.type,
      }).then(({ data }) => {
        this.working = false
        this.edit = false
        this.$emit('update-question', data.data)
      }).catch(error => {
        if (error.response) {
          this.$refs.form.setErrors(error.response.data.errors)
        }
        this.working = false
      })
    },

    storeShowBookingQuestion() {
      this.$store.dispatch('shows/storeBookingQuestion', {
        showId: this.model.id,
        question: this.form.question,
        type: this.type,
      }).then(({ data }) => {
        this.working = false
        this.edit = false
        this.$emit('update-question', data.data)
      }).catch(error => {
        if (error.response) {
          this.$refs.form.setErrors(error.response.data.errors)
        }
        this.working = false
      })
    },

    deleteQuestion(callback) {
      if (! this.question.id) {
        this.$emit('delete-question')
        callback.call()
        return
      }

      if (this.entity == 'guest') {
        this.$store.dispatch('guest/deleteBookingQuestion', {
          guestId: this.model.id,
          questionId: this.question.id,
        }).then(() => {
          this.$emit('delete-question')
          callback.call()
        })
      }

      if (this.entity == 'show') {
        this.$store.dispatch('shows/deleteBookingQuestion', {
          showId: this.model.id,
          questionId: this.question.id,
        }).then(() => {
          this.$emit('delete-question')
          callback.call()
        })
      }
    },

    cancelEdit() {
      this.edit = false

      if (! this.question.id) {
        this.$emit('delete-question')
      }
    }
  },

  mounted() {
    this.form.question = this.question.question
  },
}
</script>
