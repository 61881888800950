<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.73112 20.8325C7.22404 21.0972 6.6106 21.0506 6.14933 20.7124C5.68807 20.3741 5.45921 19.8031 5.55921 19.2399L6.36855 14.6009L2.96113 11.3356C2.54463 10.9384 2.39199 10.338 2.56823 9.7901C2.74446 9.24222 3.21852 8.84339 3.78847 8.76351L8.51945 8.08723L10.6553 3.82645C10.9086 3.31546 11.4296 2.99219 11.9999 2.99219C12.5702 2.99219 13.0912 3.31546 13.3445 3.82645L15.4803 8.08723L20.2113 8.76351C20.7813 8.84339 21.2553 9.24222 21.4316 9.7901C21.6078 10.338 21.4552 10.9384 21.0387 11.3356L17.6312 14.6009L18.4406 19.2409C18.5406 19.8041 18.3117 20.3751 17.8505 20.7134C17.3892 21.0516 16.7757 21.0982 16.2687 20.8335L11.9999 18.6276L7.73112 20.8325Z" :stroke="fill" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "ProSettingsIcon",
  props: {
    fill: {
      type: String,
      default: "#737373"
    }
  }
};
</script>
