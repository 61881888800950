<template>
  <div @dragover.prevent="dragOverInput" @dragleave="dragLeaveOrEnd" @dragend="dragLeaveOrEnd" @drop.prevent="dropFile">
    <div v-for="(image, uuid) in images" :key="`${name}-${uuid}`" class="relative group">
      <modal>
        <img :src="image" :alt="nameStartCase" class="h-40 object-contain">
        <template #content>
          <div class="bg-white p-1">
            <img :src="image" style="max-height: 80vh; max-width: 80vw" class="object-cover">
          </div>
        </template>
      </modal>
      <div class="opacity-0 group-hover:opacity-100 absolute z-10 top-0 left-0 flex items-center justify-center transition duration-150 ease-in-out">
        <alert-dialog @confirm="deleteMedia(uuid, $event)" confirm="Delete" type="button" class="p-1 bg-red-400 bg-opacity-75 rounded-lg mt-2 ml-2 text-white hover:bg-opacity-100">
          <TrashIcon/>

          <template #title>
            Delete {{name}}
          </template>

          <template #content>
            Are you sure you want to delete this {{name}}?
          </template>
        </alert-dialog>
      </div>
    </div>
    <div v-if="working">
      <loading-icon class="h-5 w-5 mr-4"/>
    </div>
    <div class="overflow-hidden">
      <EmptyImageUpload @change="uploadImage($event)" :uploading="uploading" :uploadingError="uploadingError" class="bg-gray-200 w-full"/>
    </div>
  </div>
</template>

<script>
  import { startCase } from 'lodash'
  import api from '@/api'
  import AlertDialog from '@/components/shared/AlertDialog'
  import EmptyImageUpload from '@/components/shared/EmptyImageUpload'
  import Modal from '@/components/shared/Modal'
  import TrashIcon from '@/components/svgs/TrashIcon'

  export default {
    name: 'ImageUploadMultiple',
    props: {
      images: [Object, Array],
      modelId: Number,
      working: Boolean,
      name: String,
      entity: String,
    },

    components: {
      AlertDialog,
      Modal,
      EmptyImageUpload,
      TrashIcon
    },

    data() {
      return {
        uploading: false,
        uploadingError: false,
      }
    },

    methods: {
      changeInput(event) {
        this.uploadImage(event);
      },
      dragOverInput() {
        this.highlight = true;
      },
      dragLeaveOrEnd() {
        this.highlight = false;
      },
      dropFile(event) {
        this.uploadImage(event);
      },

      uploadImage(e) {
        let files = [];

        if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length != 0) {
          files = e.dataTransfer.files;
        } else if (e.target.files.length != 0) {
          files = e.target.files;
        } else {
          return;
        }

        this.uploading = true
        this.uploadingError = false

        let uploadForm = new FormData()

        uploadForm.append('image', files[0])

        api.post('/uploads', uploadForm)
          .then(response => {
            this.$emit('updatedMedia', response.data.uploads[0])
          })
          .catch(() => {
              this.uploadingError = true
          })
          .finally(() => {
              this.uploading = false
              e.target.value = null
          })
      },

      deleteMedia(uuid, callback) {
        this.$store
          .dispatch(this.deleteMediaAction, {
            model_id: this.modelId,
            uuid: uuid,
          })
          .then(() => {
            callback.call()
            this.$store.dispatch(this.fetchAction, {id: this.modelId})
          })
      }
    },

    computed: {
      fetchAction() {
        return this.entity == 'guest' ? 'guest/fetchGuest' : 'shows/fetchShow'
      }, 

      deleteMediaAction() {
        return this.entity == 'guest' ? 'guest/deleteMedia' : 'shows/deleteMedia'
      },

      nameStartCase() {
        return startCase(this.name)
      }
    },
  }
</script>