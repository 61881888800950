<template>
  <div>
    <div v-if="working" class="mt-12 max-w-3xl w-full flex items-center justify-center py-6">
      <loading-icon class="h-2 text-pink-500" />
    </div>
    <div v-else class="mb-8 max-w-3xl w-full">
      <div class="flex flex-row">
        <h3 class="mt-3 text-blue-800 font-semibold text-xl">Promotional Upsells</h3>
        <ToggleSwitch :active="isEnabled" @toggled="$emit('toggleUpsells')"/>
      </div>
      <p :class="['text-grayDark font-normal mt-6 mb-10', !isEnabled ? 'opacity-40 pointer-events-none' : '']">Offer Shows that want to book you some extra promotional options on your social media, website, etc.</p>
      <template :class="[!isEnabled ? 'opacity-40 pointer-events-none' : '']" v-if="packages.length">
        <div class="space-y-8">
          <UpsellPackageRow
            v-for="(upsellPackage, index) in packages"
            :key="`package-${index}`"
            :upsell-package="upsellPackage"
            :model="model"
            :entity="entity"
            @delete-package="deletePackage(index)"
            @update-package="updatePackage($event, index)"
            ref="packageRows"
            :isEnabled="isEnabled"
          />
        </div>
      </template>
      <template :class="[!isEnabled ? 'opacity-40 pointer-events-none' : '']" v-else>
        <div class="h-12 mb-2 flex justify-center items-center text-sm text-gray-500 bg-gray-50 rounded-lg border-2 border-dashed opacity-75">
          No Promotionall Upsell packages added.
        </div>
      </template>
      <div :class="[!isEnabled ? 'opacity-40 pointer-events-none' : '']" class="mt-12">
        <button type="button" class="text-pink-500 underline" @click.prevent="addPackage">Add Promotional Upsell +</button>
      </div>
    </div>
  </div>
</template>

<script>
  import UpsellPackageRow from './UpsellPackageRow'
  import ToggleSwitch from '@/components/shared/ToggleSwitch'

  export default {
    name: 'UpsellPackages',

    components: {UpsellPackageRow, ToggleSwitch},

    props: {
      model: Object,
      entity: String,
      isEnabled: {
        type: Boolean,
        required: true
      }
    },

    data() {
      return {
        working: true,
        packages: []
      }
    },

    methods: {
      addPackage() {
        this.packages.push({
          title: '',
          description: '',
          price: 0,
          currency: 'USD',
          url: '',
          social_handle: '',
        })

        this.$nextTick(() => {
          this.$refs.packageRows[this.$refs.packageRows.length - 1].edit = true
        })
      },

      updatePackage(upsellPackage, index) {
        this.packages.splice(index, 1, upsellPackage)
      },

      deletePackage(index) {
        this.packages.splice(index, 1)
      }
    },

    mounted() {
      if (this.entity == 'show') {
        this.$store
          .dispatch(`shows/fetchUpsellPackages`, this.model.id)
          .then(({ data }) => {
            this.packages = data.data
          })
          .finally(() => this.working = false)
      } else {
        this.$store
          .dispatch(`guest/fetchUpsellPackages`, this.model.id)
          .then(({ data }) => {
            this.packages = data.data
          })
          .finally(() => this.working = false)
      }
    }
  }
</script>