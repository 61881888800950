<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 4.33301H20" :stroke="fill" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.1484 9.33301H19.9984" :stroke="fill" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.5 9.33301H4H5.25V4.33301L4 5.58301" :stroke="fill" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11 15.333H20" :stroke="fill" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.1484 20.333H19.9984" :stroke="fill" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4 15.333H6.5V17.333L4 18.833V20.333H6.75" :stroke="fill" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "BookingFlowStepsIcon",
  props: {
    fill: {
      type: String,
      default: "#737373"
    }
  }
};
</script>
