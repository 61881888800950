<template>
  <div class="flex flex-col md:flex-row md:justify-between">
    <div class="w-64 flex-shrink-0">
      <span class="text-grayDark font-medium" for="press_kit_rss">RSS Feed Link</span>
    </div>
    <div class="md:ml-8 mt-1 md:mt-0 flex-1 flex items-center justify-between">
      <div v-if="! edit" class="w-full flex items-center justify-between">
        <span class="font-semibold text-blue-800">{{ model.press_kit_rss }}</span>
        <Edit @edit="edit = true"/>
      </div>
      <div v-else class="w-full">
        <ValidationObserver ref="pressKitRssForm" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(update)" method="post">
            <div>
              <ValidationProvider mode="lazy" rules="required|max:255" vid="press_kit_rss" name="RSS" v-slot="{ errors }">
                <div>
                  <div class="rounded-lg shadow-sm">
                    <label for="press_kit_rss" class="sr-only">RSS</label>
                    <input name="press_kit_rss" id="press_kit_rss" type="text" class="guestio-form-input" v-model="form.press_kit_rss" placeholder="RSS" />
                  </div>
                  <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
            <div class="mt-4 flex items-center justify-end">
              <SaveOrCancel @cancel="edit = false" :working="working"/>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
  import Edit from "@/components/shared/Edit";
  import SaveOrCancel from "@/components/shared/SaveOrCancel";

  export default {
    components: {Edit, SaveOrCancel},

    props: {
      model: Object,
      entity: String,
    },

    data() {
      return {
        working: false,
        edit: false,
        form: {
          press_kit_rss: null,
        }
      }
    },

    computed: {
      updateAction() {
        return this.entity == 'guest' ? 'guest/updateGuest' : 'shows/updateShow'
      },
    },

    watch: {
      model(){
        if (this.model) {
          this.form.press_kit_rss = this.press_kit_rss
        }
      },

      edit(edit) {
        if (edit) return

        this.form.press_kit_rss = this.model.press_kit_rss
      }
    },

    methods: {
      update() {
        this.working = true

        this.$store.dispatch(this.updateAction, {
          id: this.model.id,
          press_kit_rss: this.form.press_kit_rss,
        })
          .then(() => {
            this.working = false
            this.edit = false
          })
          .catch(error => {
            this.working = false
            if (error.response) {
              this.$refs.pressKitRssForm.setErrors(error.response.data.errors)
            }
          })
      }
    }
  }
</script>
