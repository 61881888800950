<template>
  <div class="flex flex-col align-center w-80 lg:mr-12 pb-5 px-1 rounded-xl bg-white shadow-lg">
    <div v-for="(item, index) in items" :key="index">
      <div v-if="!item.hide" :key="index" class="option-cont" @click="changeSection(index + 1)">
        <component :is="item.icon" class="w-7 h-7 ml-7" :fill="section == (index + 1) ? '#6304D1' : '#737373'"></component>
        <h1 v-bind:class="['ml-2 font-light text-grayDark text-lg', section == index + 1 ? 'text-blue-800' : '']">{{ item.title }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
  import GuestDetalsIcon from '@/components/svgs/GuestDetailsIcon'
  import PitchIcon from '@/components/svgs/PitchIcon'
  import BookingFlowStepsIcon from '@/components/svgs/BookingFlowStepsIcon'
  import ProSettingsIcon from '@/components/svgs/ProSettingsIcon'
  import BookingLinksIcon from '@/components/svgs/BookingLinksIcon'
  import DangerZoneIcon from '@/components/svgs/DangerZoneIcon'
  import ProStatsIcon from '@/components/svgs/ProStatsIcon'

  export default {
    components: {
      GuestDetalsIcon,
      PitchIcon,
      BookingFlowStepsIcon,
      ProSettingsIcon,
      BookingLinksIcon,
      DangerZoneIcon,
      ProStatsIcon,
    },

    props: {
      isShow: {type: Boolean, default: false},
    },

    data() {
      return {
        section: 1,
        items: [
          {
            title: 'Guest Details & Presskit',
            icon: 'GuestDetalsIcon',
          },
          {
            title: 'Pitch',
            icon: 'PitchIcon',
          },
          {
            title: 'Booking Flow Steps',
            icon: 'BookingFlowStepsIcon',
          },
          {
            title: 'Pro Stats',
            icon: 'ProStatsIcon',
          },
          {
            title: 'Pro Settings',
            icon: 'ProSettingsIcon',
          },
          {
            title: 'Booking Links',
            icon: 'BookingLinksIcon',
          },
          {
            title: 'Danger Zone',
            icon: 'DangerZoneIcon',
          },
        ]
      }
    },

    mounted() {
      this.hideSections();
    },

    methods: {
      hideSections() {
        this.items = this.items.map(item => {
          let hide = false;
          if (item.title == 'Booking Links') {
            hide = this.isShow;
          }
          if (item.title == 'Pro Stats') {
            hide = !this.isShow;
          }
          return {
              ...item,
              hide: hide,
            }
        });
      },

      changeSection(index) {
        this.section = index;
        this.$emit('changeSection', index);
      },
    }
  }
</script>

<style scoped>
.option-cont {
  @apply flex flex-row mt-7 cursor-pointer;
}
</style>