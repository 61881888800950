<template>
  <div class="flex flex-col md:flex-row md:justify-between mb-4">
    <div class="w-64 flex-shrink-0 mb-2">
      <span class="text-grayDark font-medium">Categories</span>
    </div>
    <div class="md:ml-8 mt-1 md:mt-0 flex-1 flex items-center justify-between">
      <div v-if="! edit" class="w-full flex items-center justify-between">
        <div class="flex items-center flex-wrap -mx-1 -my-2 w-2/3">
          <span v-for="category in profile.categories" :key="`category-${category.id}`" class="p-1 my-1 truncate text-sm font-light text-white bg-purple-50 rounded-full px-4 py-2">
            <span v-if="category.parent_id">{{category.parent_name}}: </span> <span class="font-medium">{{ category.name }}</span>
          </span>
        </div>
        <Edit @edit="edit = true"/>
      </div>
      <div v-else class="w-full">
        <ValidationObserver ref="expertiseForm" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(update)" method="post">
            <div>
              <CategorySelect @selected="onCategorySelected" :values="form.categories"/>
            </div>
            <div class="mt-4 flex items-center justify-end">
              <SaveOrCancel @cancel="edit = false" :working="working"/>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState} from "vuex";
  import Edit from "@/components/shared/Edit";
  import SaveOrCancel from "@/components/shared/SaveOrCancel";
  import CategorySelect from '@/components/shared/CategorySelect';

  export default {
    name: 'ProfileCategories',
    components: {Edit, SaveOrCancel, CategorySelect},

    props: {
      modelId: Number,
      entity: String,
    },

    data() {
      return {
        working: false,
        edit: false,
        form: {
          categories: null,
        },
      }
    },

    watch: {
      edit(edit) {
        if (edit) return

        this.form.categories = this.profile.categories
      },

      profile() {
        return this.entity == 'guest' ? this.guest : this.show
      },
    },

    mounted() {
      this.form.categories = this.profile.categories
      this.form.id = this.profile.id
    },

    methods: {
      onCategorySelected(categories) {
        this.form.categories = categories
      },

      update() {
        this.working = true

        let params = { id: this.form.id}
        let categoriesIds = this.form.categories.map(c => c.id);
        params.categories = categoriesIds;

        this.$store.dispatch(this.updateAction, params)
          .then(() => {
            this.working = false
            this.edit = false
          })
          .catch(error => {
            this.working = false
            if (error.response) {
              this.$refs.expertiseForm.setErrors(error.response.data.errors)
            }
          })
      }
    },

    computed: {
      ...mapState({
        show: state => state.shows.show,
        guest: state => state.guest.guest,
        user: state => state.auth.user
      }),

      profile() {
        return this.entity == 'guest' ? this.guest : this.show
      },

      updateAction() {
        return this.entity == 'guest' ? 'guest/updateGuest' : 'shows/updateShow'
      },
    },
  }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>