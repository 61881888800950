<template>
  <div class="flex flex-col md:flex-row md:justify-between">
    <div class="w-64 flex-shrink-0">
      <span class="text-grayDark font-medium mb-2">Remove Guestio branding</span>
    </div>
    <div class="md:ml-8 mt-1 md:mt-0 flex-1 flex items-center justify-between">
      <div v-if="! edit" class="w-full flex items-center justify-between">
        <span class="text-blue-800 font-semibold">{{ profile.remove_branding ? 'Yes' : 'No' }}</span>
        <Edit @edit="edit = true"/>
      </div>
      <div v-else class="w-full">
        <ValidationObserver ref="nameForm" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(update)" method="post">
            <div>
              <ValidationProvider mode="lazy" rules="required" vid="remove_branding" name="Remove branding" v-slot="{ errors }">
                <div>
                  <div class="rounded-lg">
                    <label for="remove_branding" class="sr-only">Remove Guestio branding</label>
                    <select name="remove_branding" id="remove_branding" class="form-select guestio-form-input" v-model="form.remove_branding">
                      <option :value="false">No</option>
                      <option :value="true">Yes</option>
                    </select>
                  </div>
                  <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
            <div class="mt-4 flex items-center justify-end">
              <SaveOrCancel @cancel="edit = false" :working="working"/>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState} from "vuex";
  import Edit from "@/components/shared/Edit";
  import SaveOrCancel from "@/components/shared/SaveOrCancel";

  export default {
    components: {Edit, SaveOrCancel},

    props: {
      model: Object,
      entity: String,
    },

    data() {
      return {
        working: false,
        edit: false,
        form: {
          remove_branding: null,
        }
      }
    },

    watch: {
      edit(edit) {
        if (edit) return

        this.form.remove_branding = this.model.remove_branding
      }
    },

    mounted() {
      this.form.remove_branding =  this.model.remove_branding
      this.form.id = this.model.id
    },

    methods: {
      update() {
        this.working = true

        this.$store.dispatch(this.updateAction, this.form)
          .then(() => {
            this.working = false
            this.edit = false
          })
          .catch(error => {
            this.working = false
            if (error.response) {
              this.$refs.nameForm.setErrors(error.response.data.errors)
            }
          })
      }
    },

    computed: {
      ...mapState({
        show: state => state.shows.show,
        guest: state => state.guest.guest,
        user: state => state.auth.user
      }),

      profile() {
        return this.entity == 'guest' ? this.guest : this.show
      },

      updateAction() {
        return this.entity == 'guest' ? 'guest/updateGuest' : 'shows/updateShow'
      },
    },
  }
</script>