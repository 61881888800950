<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 6V18.75C18 19.993 16.973 21 15.731 21H8.231C6.988 21 6 19.993 6 18.75V6" stroke="#FF2F3B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.5 6H4.5" stroke="#FF2F3B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 3H14" stroke="#FF2F3B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14 10V17" stroke="#FF2F3B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 17V10" stroke="#FF2F3B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "TrashAltIcon2",
  props: {
    fill: {
      type: String,
      default: "#737373"
    }
  }
};
</script>
