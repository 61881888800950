<template>
  <div class="relative">
    <div v-if="!isEnabled" class="absolute inset-0 z-10 bg-white bg-opacity-50"></div>
    <div>
      <div class="flex items-center justify-between">
        <p class="text-grayDark font-light max-w-2xl">
          Enable users to send a customized pitch. You still decide whether to
          accept the booking request or negotiate the price.
        </p>
      </div>
    </div>
    <div class="mt-10">
      <div v-if="working" class="py-6 flex items-center justify-center">
        <loading-icon class="h-2 text-pink-500" />
      </div>
      <div v-else>
        <div class="space-y-14">
          <div class="max-w-lg">
            <Checkbox
              fontWeight="font-normal"
              hover="hover:text-violet"
              size="w-5.5 h-5.5"
              borderWidth="border"
              :value="pitchSettings.free_pitch_notifications"
              title="Enable Pitch Notifications"
              name="paid"
              :checked="pitchSettings.free_pitch_notifications"
              class="mb-3"
              @on-toggle="toggleNotifications"
            />

            <div>
              <p class="text-grayDark font-light text-sm">
                You can disable the email and SMS notifications when someone is sending you a 
                free pitch (you will still receive all the notifications if they pay for the 
                <span class="font-bold">guaranteed response</span> option)
              </p>
            </div>
          </div>

          <div>
            <Checkbox
              fontWeight="font-normal"
              hover="hover:text-violet"
              size="w-5.5 h-5.5"
              borderWidth="border"
              :value="pitchSettings.guaranteed_response"
              title="Offer a Guaranteed Response"
              subtitle="(within 48 hours)*"
              name="paid"
              :checked="pitchSettings.guaranteed_response"
              class="mb-3"
              @on-toggle="toggleGuaranteedResponse"
            />
            <div v-if="pitchSettings.guaranteed_response">
              <label for="guaranteed-response-price" class="block font-light mb-2">Guaranteed Response Price</label>
              <p
                class="pointer-events-none absolute mt-3 ml-5 h-5 w-5 text-gray-500"
              >
                $
              </p>
              <div class="flex flex-row">
                <input
                  id="guaranteed-response-price"
                  type="number"
                  min="5"
                  class="bg-white border-solid border-2 border-gray-400 rounded-3xl py-3 px-9 w-1/2"
                  v-model="pitchSettings.guaranteed_response_price"
                  @input="updatePitchSettings"
                />
              </div>
              <p class="text-grayDark text-sm font-light mt-9 w-1/2">
                *Checking this box does not obligate you to accept the pitch, but it does require 
                your response with 48 hours or a full refund will be given to the user.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import Checkbox from "@/components/shared/Checkbox";

export default {
  name: "ProfilePitch",
  components: { Checkbox },

  props: {
    model: { type: Object, required: true },
    entity: { type: String, required: true },
    isEnabled: { type: Boolean, required: true }
  },


  data() {
    return {
      working: true,
      pitchSettings: {}
    };
  },

  mounted() {
    this.$store
      .dispatch(`${this.entity}/fetchPitchSettings`, this.model.id)
      .then(({ data }) => {
        this.pitchSettings = data.data;
      })
      .finally(() => this.working = false);
  },

  methods: {
    updatePitchSettings: debounce(function() {
      this.$store
        .dispatch(`${this.entity}/updatePitchSettings`, {
          ...this.pitchSettings,
          modelId: this.model.id,
        })
        .catch(() => {
          this.$toast.error("There was an error. Try again later.");
        });
    }, 750),

    toggleNotifications() {
      this.pitchSettings.free_pitch_notifications = !this.pitchSettings.free_pitch_notifications;
      this.updatePitchSettings();
    },

    toggleGuaranteedResponse() {
      this.pitchSettings.guaranteed_response = !this.pitchSettings.guaranteed_response;
      this.updatePitchSettings();
    },
  }
};
</script>
