<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 5.33301L20 4.33301" :stroke="fill" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M20 8.33301H21" :stroke="fill" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16 4.33301V3.33301" :stroke="fill" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21 12.333V18.333C21 19.99 19.657 21.333 18 21.333H6C4.343 21.333 3 19.99 3 18.333V6.33301C3 4.67601 4.343 3.33301 6 3.33301H12" :stroke="fill" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7337 13.5987L7.8717 12.4537C7.3677 12.2517 7.3787 11.5347 7.8897 11.3487L14.7077 8.86972C15.1787 8.69872 15.6347 9.15472 15.4637 9.62572L12.9847 16.4437C12.7987 16.9537 12.0817 16.9657 11.8797 16.4617L10.7337 13.5987V13.5987Z" :stroke="fill" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "BookingLinksIcon",
  props: {
    fill: {
      type: String,
      default: "#737373"
    }
  }
};
</script>
