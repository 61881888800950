<template>
  <div class="flex flex-col md:flex-row md:justify-between">
    <div class="w-64 flex-shrink-0">
      <div class="flex flex-col">
        <span class="text-grayDark font-medium" for="press_kit_rss">Logos</span>
        <span class="text-grayDark font-normal opacity-70 text-sm mt-3">This will only be shown if people download your Press Kit PDF</span>
      </div>
    </div>
    <div class="md:ml-8 mt-1 md:mt-0 flex-1 flex items-center justify-between">
      <ImageUploadMultiple
        class="w-full grid grid-cols-1 gap-6"
        name="logo"
        :entity="entity"
        :working="working"
        :images="model.logos"
        :modelId="model.id"
        @updatedMedia="uploadImage"
      />
    </div>
  </div>
</template>

<script>
  import ImageUploadMultiple from '@/components/shared/ImageUploadMultiple';

  export default {
    props: {
      model: Object,
      entity: String,
    },

    components: {
      ImageUploadMultiple,
    },

    data() {
      return {
        working: false,
        form: {
          logoUrl: null,
        }
      }
    },

    computed: { 
      updateAction() {
        return this.entity == 'guest' ? 'guest/updateGuest' : 'shows/updateShow'
      },
    },

    methods: {
      update() {
        this.working = true

        this.$store.dispatch(this.updateAction, {
          id: this.model.id,
          logoUrl: this.form.logoUrl,
        })
          .then(() => {
            this.working = false
            this.form.logoUrl = null
          })
          .catch(() => {
            this.working = false
          })
      },

      uploadImage(url) {
        this.form.logoUrl = url
        this.update()
      },
    }
  }
</script>