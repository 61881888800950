<template>
  <div :class="[!isEnabled ? 'opacity-40 pointer-events-none' : '']">
    <div v-if="! edit" class="flex justify-between">
      <div class="rounded-lg border-2 flex-1 px-6 py-4">
        <div class="header flex items-center justify-between text-blue-800">
          <h3 class="text-blue-900 text-xl font-bold">
            {{ upsellPackage.title }}
          </h3>
          <span class="price text-2xl font-bold">
            ${{ upsellPackage.price }}
          </span>
        </div>
        <div class="description mt-2 leading-normal text-lg text-gray-500 whitespace-pre-wrap">{{ upsellPackage.description }}</div>
        <div class="meta mt-6 space-y-2" v-if="upsellPackage.url || upsellPackage.social_handle">
          <p v-if="upsellPackage.url && upsellPackage.url.length">
            <a :href="upsellPackage.url" target="_blank" class="text-indigo-500">
              {{ upsellPackage.url }}
            </a>
          </p>
          <p v-if="upsellPackage.social_handle && upsellPackage.social_handle.length" class="text-pink-500">
            {{ upsellPackage.social_handle }}
          </p>
        </div>
      </div>
      <div>
        <Edit @edit="edit = true" class=""/>
        <ConfirmDialog
          @confirm="deletePackage"
          confirm-action="Yes, Delete"
          dialog-title="Delete Promotional Upsell?"
          dialog-text="Are you sure you want to delete this promotional upsell package?"
          class="ml-8 mt-4 flex items-center text-pink-500"
        >
          <TrashAltIcon2 class="w-6 h-6 text-red-500" />
          <span class="ml-2 text-red-500">Delete</span>
        </ConfirmDialog>
      </div>
    </div>
    <div v-else class="w-full">
      <div class="rounded-lg border-2 flex-1 px-6 sm:px-8 py-6 sm:py-8">
        <ValidationObserver ref="packageForm" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(update)" method="post">
            <div class="space-y-6">
              <div class="space-y-2">
                <label for="title" class="capitalize text-sm font-semibold block">
                  Title
                </label>
                <div>
                  <ValidationProvider mode="lazy" rules="required|max:255" vid="title" name="Title" v-slot="{ errors }">
                    <TextInput v-model="form.title" :errors="errors" placeholder="Title" id="title" />
                  </ValidationProvider>
                </div>
              </div>
              <div class="space-y-2">
                <label for="description" class="capitalize text-sm font-semibold block">
                  Description
                </label>
                <div>
                  <ValidationProvider mode="lazy" vid="description" name="Description" v-slot="{ errors }">
                    <textarea-autosize class="shadow-sm guestio-form-textarea text-sm leading-6 transition-all duration-300" name="description" id="description" v-model="form.description" :min-height="200" />
                    <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
                  </ValidationProvider>
                </div>
              </div>
              <div class="space-y-2">
                <label for="price" class="capitalize text-sm font-semibold block">
                  Price
                </label>
                <div class="flex space-x-2">
                  <ValidationProvider rules="required" vid="currency" name="Currency" v-slot="{ errors }">
                    <div class="bg-transparent rounded-full bg-gray-50 py-2 px-5 flex items-center w-24">
                      <select class="bg-transparent w-full" v-model="form.currency">
                        <option value="USD">USD</option>
                      </select>
                      <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>

                  <ValidationProvider name="Price" vid="price" v-slot="{ errors }">
                    <div class="bg-transparent rounded-full bg-gray-50 py-2 px-5 flex items-center w-32">
                      <input
                        id="price"
                        type="number"
                        oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                        class="text-input bg-transparent w-full focus:border focus:border-pink-500"
                        v-model="form.price"
                      >
                    </div>
                    <p class="form-error text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
                  </ValidationProvider>
                </div>
              </div>

              <div class="space-y-2">
                <label for="url" class="capitalize text-sm font-semibold block">
                  URL <span class="font-normal text-gray-500">(Optional)</span>
                </label>
                <div>
                  <ValidationProvider mode="lazy" rules="max:255" vid="url" name="Url" v-slot="{ errors }">
                    <TextInput v-model="form.url" :errors="errors" placeholder="https://guestio.com" id="url" />
                  </ValidationProvider>
                </div>
              </div>

              <div class="space-y-2">
                <label for="social_handle" class="capitalize text-sm font-semibold block">
                  Social Media Handle <span class="font-normal text-gray-500">(Optional)</span>
                </label>
                <div>
                  <ValidationProvider mode="lazy" rules="max:255" vid="social_handle" name="Social Media Handle" v-slot="{ errors }">
                    <TextInput v-model="form.social_handle" :errors="errors" placeholder="@guestio" id="social_handle" />
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="mt-4 flex items-center justify-end">
              <SaveOrCancel @cancel="cancelEdit" :working="working"/>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/shared/TextInput";
import TrashAltIcon2 from "@/components/svgs/TrashAltIcon2";
import Edit from "@/components/shared/Edit";
import SaveOrCancel from "@/components/shared/SaveOrCancel";
import ConfirmDialog from "@/components/shared/ConfirmDialog";

export default {
  name: 'UpsellPackageRow',

  props: {
    upsellPackage: Object,
    model: Object,
    entity: String,
    isEnabled: {
        type: Boolean,
        required: true
      }
  },

  components: {
    TrashAltIcon2,
    TextInput,
    Edit,
    SaveOrCancel,
    ConfirmDialog,
  },

  data() {
    return {
      working: false,
      edit: false,
      form: {
        title: null,
        description: null,
        price: null,
        currency: 'USD',
        url: null,
        social_handle: null,
      },
    }
  },

  watch: {
    edit(edit) {
      if (edit) {
        return
      }

      this.resetForm()
    },
  },

  methods: {
    update() {
      this.working = true

      if (this.entity == 'guest') {
        this.handleGuestUpsellPackage()
      }

      if (this.entity == 'show') {
        this.handleShowUpsellPackage()
      }
    },

    handleGuestUpsellPackage() {
      if (this.upsellPackage.id) {
        this.updateGuestUpsellPackage()
      } else {
        this.storeGuestUpsellPackage()
      }
    },

    updateGuestUpsellPackage() {
      this.$store.dispatch('guest/updateUpsellPackage', {
        guestId: this.model.id,
        packageId: this.upsellPackage.id,
        ...this.form
      }).then(({ data }) => {
        this.working = false
        this.edit = false
        this.$emit('update-package', data.data)
      }).catch(() => {
        this.$toast.error('The upsell package was not updated.')
        this.working = false
      })
    },

    storeGuestUpsellPackage() {
      this.$store.dispatch('guest/storeUpsellPackage', {
        guestId: this.model.id,
        ...this.form,
      }).then(({ data }) => {
        this.working = false
        this.edit = false
        this.$emit('update-package', data.data)
      }).catch(() => {
        this.$toast.error('The upsell package was not created.')
        this.working = false
      })
    },

    handleShowUpsellPackage() {
      if (this.upsellPackage.id) {
        this.updateShowUpsellPackage()
      } else {
        this.storeShowUpsellPackage()
      }
    },

    updateShowUpsellPackage() {
      this.$store.dispatch('shows/updateUpsellPackage', {
        showId: this.model.id,
        packageId: this.upsellPackage.id,
        ...this.form,
      }).then(({ data }) => {
        this.working = false
        this.edit = false
        this.$emit('update-package', data.data)
      }).catch(() => {
        this.$toast.error('The upsell package was not updated.')
        this.working = false
      })
    },

    storeShowUpsellPackage() {
      this.$store.dispatch('shows/storeUpsellPackage', {
        showId: this.model.id,
        ...this.form,
      }).then(({ data }) => {
        this.working = false
        this.edit = false
        this.$emit('update-package', data.data)
      }).catch(() => {
        this.$toast.error('The upsell package was not created.')
        this.working = false
      })
    },

    deletePackage(callback) {
      if (! this.upsellPackage.id) {
        this.$emit('delete-package')
        callback.call()
        return
      }

      if (this.entity == 'guest') {
        this.$store.dispatch('guest/deleteUpsellPackage', {
          guestId: this.model.id,
          packageId: this.upsellPackage.id,
        }).then(() => {
          this.$emit('delete-package')
          callback.call()
        }).catch(() => {
          this.$toast.error('The upsell package was not deleted.')
        })
      }

      if (this.entity == 'show') {
        this.$store.dispatch('shows/deleteUpsellPackage', {
          showId: this.model.id,
          packageId: this.upsellPackage.id,
        }).then(() => {
          this.$emit('delete-package')
          callback.call()
        }).catch(() => {
          this.$toast.error('The upsell package was not deleted.')
        })
      }
    },

    cancelEdit() {
      this.edit = false

      if (! this.upsellPackage.id) {
        this.$emit('delete-package')
      }
    },

    resetForm() {
      this.form.title = this.upsellPackage.title
      this.form.description = this.upsellPackage.description
      this.form.price = this.upsellPackage.price
      this.form.currency = this.upsellPackage.currency
      this.form.url = this.upsellPackage.url
      this.form.social_handle = this.upsellPackage.social_handle
    }
  },

  mounted() {
    this.resetForm()
  },
}
</script>
